// Generated by Framer (cbb0119)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/FPBxfpjN5";
import Icon from "./evEFWk4zg";
const IconFonts = getFonts(Icon);

const cycleOrder = ["ZGVUP_gjD", "bgWeH1CGZ"];

const serializationHash = "framer-Zvo1G"

const variantClassNames = {bgWeH1CGZ: "framer-v-t3bs0u", ZGVUP_gjD: "framer-v-144uwyy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "ZGVUP_gjD", "Variant 2": "bgWeH1CGZ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ZGVUP_gjD"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ZGVUP_gjD", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-144uwyy", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ZGVUP_gjD"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31))", ...style}} {...addPropertyOverrides({bgWeH1CGZ: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider height={24} width={"24px"} y={((componentViewport?.y || 0) + (16 + ((((componentViewport?.height || 200) - 32) - 24) / 2)))}><motion.div className={"framer-1e9nkb3-container"} layoutDependency={layoutDependency} layoutId={"xk81NwoSK-container"}><Icon height={"100%"} id={"xk81NwoSK"} layoutId={"xk81NwoSK"} style={{height: "100%", width: "100%"}} variant={"HEz8u4LoQ"} width={"100%"} {...addPropertyOverrides({bgWeH1CGZ: {variant: "s6LZpsYtE"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-10duaol"} data-styles-preset={"FPBxfpjN5"} style={{"--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-52b9f1ec-8a5c-4a68-9b06-4560bba78883, rgb(255, 255, 255)))"}}>BUTTON</motion.p></React.Fragment>} className={"framer-146wj73"} data-framer-name={"label"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"ZGVUP_gjDTLJ3N9kjh"} style={{"--extracted-r6o4lv": "var(--token-52b9f1ec-8a5c-4a68-9b06-4560bba78883, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Zvo1G.framer-4hyj19, .framer-Zvo1G .framer-4hyj19 { display: block; }", ".framer-Zvo1G.framer-144uwyy { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: center; overflow: visible; padding: 16px 28px 16px 22px; position: relative; width: min-content; }", ".framer-Zvo1G .framer-1e9nkb3-container { flex: none; height: 24px; position: relative; width: 24px; }", ".framer-Zvo1G .framer-146wj73 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Zvo1G.framer-144uwyy { gap: 0px; } .framer-Zvo1G.framer-144uwyy > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-Zvo1G.framer-144uwyy > :first-child { margin-left: 0px; } .framer-Zvo1G.framer-144uwyy > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"bgWeH1CGZ":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermIx0DEZ3S: React.ComponentType<Props> = withCSS(Component, css, "framer-Zvo1G") as typeof Component;
export default FramermIx0DEZ3S;

FramermIx0DEZ3S.displayName = "test";

FramermIx0DEZ3S.defaultProps = {height: 200, width: 200};

addPropertyControls(FramermIx0DEZ3S, {variant: {options: ["ZGVUP_gjD", "bgWeH1CGZ"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramermIx0DEZ3S, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...IconFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})